import React from 'react';
import './Admin.css';
import { Link } from 'react-router-dom';

const Admin = () => {
  return (
    <div>
      <div className="header">
        <div className="nav">
          <Link to="/admin/user/0597882781/server-boost" className="button">Orders (Server Boost)</Link>
          <Link to="/admin/user/0597882781/accounts" className="button">Orders (Accounts)</Link>
        </div>
      </div>
      <div className="container">
        <div className="card">
          <h2>Admin Panel</h2>
          <p>Welcome to the Admin Panel. Here you can manage orders, view stats, and more.</p>
          {/* Existing content goes here */}
        </div>
      </div>
    </div>
  );
};

export default Admin;

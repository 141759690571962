import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';
import './orders.css';
import LoadingIcon from './Loading.svg'; // Ensure the path is correct
import CheckmarkIcon from './checkmark.svg'; // Import the checkmark SVG
import ImportantNoteIcon from './important_note_icon1.svg'; // Import the important note SVG

const apiUrl = 'https://chat-panel-backend-7a08673c7031.herokuapp.com';
const socket = io(apiUrl);

function Orders() {
  const { slug } = useParams();
  const [product, setProduct] = useState('');
  const [status, setStatus] = useState('Available');
  const [deliveryInfo, setDeliveryInfo] = useState('');
  const [requestedAt, setRequestedAt] = useState('');
  const [deliveredAt, setDeliveredAt] = useState(''); // New state for DeliveredAt
  const [loading, setLoading] = useState(true);
  const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState('');
  const [countdown, setCountdown] = useState('');

  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [orderId, setOrderId] = useState('');
  const [message, setMessage] = useState('');
  const [isChatActive, setisChatActive] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  
  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/orders/${slug}`);
        if (response.data) {
          setProduct(response.data.product);
          setStatus(response.data.status);
          setDeliveryInfo(response.data.deliveryInfo);
		  setOrderId(response.data.orderId); // Set order ID

          if (response.data.estimatedDeliveryTime) {
            setEstimatedDeliveryTime(response.data.estimatedDeliveryTime);
          }

          if (response.data.RequestedAt) {
            setRequestedAt(new Date(response.data.RequestedAt).toLocaleString());
          }

          if (response.data.DeliveredAt) {
            setDeliveredAt(new Date(response.data.DeliveredAt).toLocaleString());
          }
          if (response.data.reportMessages && response.data.reportMessages.length > 0) { // Ensure the property name is correct
            setisChatActive(true);
            setChatMessages(response.data.reportMessages);
          }
        }
      } catch (error) {
        console.error('Error fetching order data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderData();

    socket.emit('joinRoom', slug);

    socket.on('orderUpdated', (updatedOrder) => {
      if (updatedOrder.slug === slug) {
        if (updatedOrder.status) {
          setStatus(updatedOrder.status);
        }

        if (updatedOrder.deliveryInfo) {
          setDeliveryInfo(updatedOrder.deliveryInfo);
        }

        if (updatedOrder.estimatedDeliveryTime) {
          setEstimatedDeliveryTime(updatedOrder.estimatedDeliveryTime);
        }

        if (updatedOrder.RequestedAt) {
          setRequestedAt(new Date(updatedOrder.RequestedAt).toLocaleString());
        }

        if (updatedOrder.DeliveredAt) {
          setDeliveredAt(new Date(updatedOrder.DeliveredAt).toLocaleString());
        }
		
        if (updatedOrder.reportMessages) {
          setChatMessages(updatedOrder.reportMessages);
        }
      }
    });

    return () => {
      socket.off('orderUpdated');
      socket.emit('leaveRoom', slug);
    };
  }, [slug]);

  useEffect(() => {
    if (status === 'Processing' && estimatedDeliveryTime) {
      const storedRequestedAt = localStorage.getItem(`requestedAt_${slug}`);
      const requestedTimestamp = storedRequestedAt ? new Date(storedRequestedAt) : new Date(requestedAt);
      const currentTime = new Date();

      // Calculate the elapsed time since the order was requested
      const elapsedTime = Math.floor((currentTime - requestedTimestamp) / 1000);

      // Convert estimated delivery time to total seconds
      const [hours, minutes, seconds] = estimatedDeliveryTime.split(':').map(Number);
      let totalEstimatedSeconds = hours * 3600 + minutes * 60 + seconds;

      // Calculate remaining time in seconds
      let remainingSeconds = totalEstimatedSeconds - elapsedTime;

      if (remainingSeconds < 0) {
        remainingSeconds = 0;
      }

      const countdownInterval = setInterval(() => {
        if (remainingSeconds > 0) {
          remainingSeconds -= 1;
          const hrs = String(Math.floor(remainingSeconds / 3600)).padStart(2, '0');
          const mins = String(Math.floor((remainingSeconds % 3600) / 60)).padStart(2, '0');
          const secs = String(remainingSeconds % 60).padStart(2, '0');
          setCountdown(`${hrs}:${mins}:${secs}`);
          localStorage.setItem(`requestedAt_${slug}`, requestedTimestamp.toISOString());
        } else {
          clearInterval(countdownInterval);
          setCountdown('00:00:00');
          localStorage.removeItem(`requestedAt_${slug}`);
        }
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [status, estimatedDeliveryTime, requestedAt, slug]);

  const requestOrder = async () => {
    if (status === 'Delivered' || status === 'Processing') {
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/orders/update-status`, { slug, status: 'Processing' });
      if (response.data.success) {
        setStatus('Processing');
        setRequestedAt(new Date().toLocaleString());
      }
    } catch (error) {
      console.error('Error updating order status:', error);
    } finally {
      setLoading(false);
    }
  };

  const downloadOrderDetails = () => {
    const deliveryInfoContent = deliveryInfo || 'No delivery info available.';
    const blob = new Blob([deliveryInfoContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `order_${slug}_details.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  
// Submit the initial report
const handleReportSubmit = async () => {
  try {
    const response = await axios.post(`${apiUrl}/api/orders/report-problem`, {
      slug,
      orderId,
      message,
    });

    if (response.data.success) {
      setisChatActive(true);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { user: 'Customer', message, timestamp: new Date() },
      ]);
    } else {
      console.error('Failed to submit report:', response.data.message);
    }
  } catch (error) {
    console.error('Error submitting report:', error);
  }
};

// Handle sending chat messages
const sendMessage = async (msg) => {
  try {
    const response = await axios.post(`${apiUrl}/api/orders/add-message`, {
      slug,
      message: msg,
      user: 'Customer',
    });

    if (response.data.success) {
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { user: 'Customer', message: msg, timestamp: new Date() },
      ]);
    } else {
      console.error('Failed to send message:', response.data.message);
    }
  } catch (error) {
    console.error('Error sending message:', error);
  }
};

  // Function to handle closing the ticket
  const handleCloseTicket = async () => {
    try {
      const response = await axios.post(`${apiUrl}/api/orders/close-ticket/${slug}`);
      if (response.data.success) {
        setOrderId('');
        setChatMessages([]);
        setisChatActive(false);
      } else {
        console.error('Failed to close ticket:', response.data.message);
      }
    } catch (error) {
      console.error('Error closing ticket:', error);
    }
  };

  return (
    <div className="orders-container">
      <div className="order-box-">
        {(status === 'Available' || status === 'Processing') && (
          <div className="important-note-container">
            <img src={ImportantNoteIcon} alt="Important Note" className="important-note-icon-" />
            <div className="tooltip">
              Delivery can take up to 6 hours (Generally less) to deliver order details
            </div>
          </div>
        )}
        <div className="product-container-">
          <span>Product: </span>
          <span className="product-box-">{product}</span>
        </div>
        <div className="status-container-">
          <span>Status: </span>
          <span className={`status-box- ${status.toLowerCase()}`}>{status}</span>
          {status === 'Delivered' && (
            <img src={CheckmarkIcon} alt="Delivered" className="checkmark-icon" />
          )}
        </div>
		{status === 'Canceled' && (
		  <div className="canceled-message">
		    This order has been canceled and can not be processed.
		  </div>
		)}
        {status === 'Processing' && (
          <div className="estimated-delivery-time-container">
            <span>Estimated Delivery Time: </span>
            <span className="estimated-delivery-time-box">{countdown}</span>
          </div>
        )}
        {status === 'Delivered' && (
          <div className="delivered-box-">
            <pre>{deliveryInfo}</pre>
          </div>
        )}
        {/* Timestamps box */}
        {(status === 'Processing' || status === 'Delivered') && (
          <div className="timestamps-box-">
            <div>
              <span>Requested At: </span>
              <span className="requested-at-box-">{requestedAt}</span>
            </div>
            {status === 'Delivered' && (
              <div>
                <span>Delivered At: </span>
                <span className="delivered-at-box-">{deliveredAt}</span>
              </div>
            )}
          </div>
        )}
      </div>
      {!loading && status === 'Delivered' && (
        <button onClick={downloadOrderDetails}>Download Order Details</button>
      )}
      {!loading && status === 'Delivered' && (
	    <div className="having-an-issue-button">
          <button onClick={() => setShowModal(true)}>Having a question? Contact us here</button>
	    </div>
      )}
      {!loading && status === 'Delivered' && (
	    <div className="additional-info-text">
          For product issues, return, and replacement, etc , please reach us and create a ticket on Kinguin Resolution Center...
		  <a
		    href="https://www.zacks.cc/Kinguin-Resolution-Center"
			target="_blank"
			rel="noopener noreferrer"
			className="link-text"
		   >
		     Here's a guide on how to open a case in Resolution Center.
		   </a>
	    </div>
      )}
      {!loading && status === 'Available' && (
        <button className="request-order-button" onClick={requestOrder}>
          Request Order Details
        </button>
      )}
      {status === 'Processing' && (
        <div>
          <img src={LoadingIcon} alt="Loading..." className="loading-icon" />
        </div>
	  )}
	  {status === 'Processing' && (
        <div className="additional-info-text">
          For product issues, return, and replacement, etc , please reach us and create a ticket on Kinguin Resolution Center...
          <a
		    href="https://www.zacks.cc/Kinguin-Resolution-Center"
			target="_blank"
			rel="noopener noreferrer"
			className="link-text"
		  >
		    Here's a guide on how to open a case in Resolution Center.
		  </a>
		</div>
      )}
	  {status === 'Canceled' && (
        <div className="additional-info-text">
          For product issues, return, and replacement, etc , please reach us and create a ticket on Kinguin Resolution Center...
          <a
		    href="https://www.zacks.cc/Kinguin-Resolution-Center"
			target="_blank"
			rel="noopener noreferrer"
			className="link-text"
		  >
		    Here's a guide on how to open a case in Resolution Center.
		  </a>
		</div>
      )}
	  
      {/* Modal- */}
      {showModal && (
        <div className="modal-overlay-">
          <div className="modal-">
            {isChatActive ? (
              <div className="chat-interface-">
			    <div className="chat-header">
                  <h2>Chat with Merchant</h2>
				  <button onClick={handleCloseTicket} className="close-ticket-button">
				    Close Ticket
				  </button>
				</div>
				<div className="order-id-container">
				  <label htmlFor="order-id" className="order-id-label">Order ID:</label>
				  <div id="order-id" className="order-id-box">
				    {orderId || 'N/A'}
				  </div>
				</div>
                <div className="chat-messages-">
                  {chatMessages.map((msg, index) => (
                    <div
                      key={index}
                      className={`chat-message- ${msg.user === 'Customer' ? 'customer' : 'merchant'}`}
                    >
                      <span className="chat-user-">{msg.user}:</span>
                      <span className="chat-text">{msg.message}</span>
                    </div>
                  ))}
                </div>
                <div className="chat-input-container-">
                  <textarea
                    className="chat-input-"
                    placeholder="Type your message..."
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        const trimmedMessage = e.target.value.trim();
                        if (trimmedMessage) {
                          sendMessage(trimmedMessage);
                          e.target.value = '';
                        }
                      }
                    }}
                  />
                </div>
                <button onClick={() => setShowModal(false)}>Close</button>
              </div>
            ) : (
              <>
                <h2>Report a Problem</h2>
                <div className="modal-input-group-">
                  <label htmlFor="order-id">Order ID:</label>
                  <input
                    type="text"
                    id="order-id"
                    placeholder="Enter your order ID on Kinguin"
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                    className="order-id-input-"
                  />
                </div>
                <div className="modal-input-group-">
                  <label htmlFor="message">Message to the merchant:</label>
                  <textarea
                    id="message"
                    placeholder="Describe your issue here..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="message-textarea-"
                  />
                </div>
                <div className="modal-button-group-">
                  <button onClick={handleReportSubmit}>Create a Ticket</button>
                  <button onClick={() => setShowModal(false)}>Close</button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Orders;

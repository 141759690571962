import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './login.css';

function Login({ setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Redirect to the page the user was trying to access before being sent to login
  const from = location.state?.from?.pathname || '/admin/user/0597882781';

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://chat-panel-backend-7a08673c7031.herokuapp.com/api/login', {
        username,
        password,
      });

      if (response.data.success) {
        localStorage.setItem('authToken', response.data.token);
        setIsAuthenticated(true); // Set the authentication state to true
        navigate(from, { replace: true }); // Redirect to the requested page after login
      } else {
        setError(response.data.message || 'Invalid login credentials');
        triggerNotification(); // Show notification on error
      }
    } catch (err) {
      setError('Login failed. Please try again.');
      triggerNotification(); // Show notification on error
    }
  };

  const triggerNotification = () => {
    setShowNotification(true); // Show the notification
    setTimeout(() => {
      setShowNotification(false); // Hide after 3 seconds
    }, 3000);
  };

  return (
    <div className="custom-login-container">
      {/* Notification area */}
      {showNotification && (
        <div className={`notification ${showNotification ? 'show' : 'hide'}`}>
          {error}
        </div>
      )}

      <h2>Login</h2>
      <form onSubmit={handleLogin} className="custom-login-form">
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;

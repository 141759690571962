import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './Chat.css';
import ImportantNoteIcon from './important_note_icon.svg'; // Import the important note SVG

const apiUrl = 'https://chat-panel-backend-7a08673c7031.herokuapp.com';

const Chat = () => {
  const { slug } = useParams();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [socket, setSocket] = useState(null);
  const [status, setStatus] = useState('Available');
  const [product, setProduct] = useState('');
  const [processedAt, setProcessedAt] = useState(null); // State to store processedAt timestamp
  const messagesEndRef = useRef(null);
  const isFirstMessageFromCustomer = useRef(true); // Ref to track if first message from customer
  const isAdminReplied = useRef(false); // Ref to track if admin has replied

  useEffect(() => {
    document.body.classList.add('chat-page');

    const fetchChatData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/link/${slug}`);
        console.log('Fetched chat data:', response.data);
        if (response.data) {
          setMessages(response.data.messages);
          setStatus(response.data.status || 'Available');
          setProduct(response.data.product);

          // Find the first message from the customer in the chat history
          const firstCustomerMessage = response.data.messages.find(msg => msg.user === 'Customer');
          if (firstCustomerMessage && isFirstMessageFromCustomer.current) {
            setProcessedAt(new Date(firstCustomerMessage.timestamp));
            isFirstMessageFromCustomer.current = false;
          }
        }
      } catch (error) {
        console.error('Error fetching chat data:', error);
      }
    };

    fetchChatData();

    const newSocket = io(apiUrl);
    setSocket(newSocket);

    newSocket.emit('join', slug);

    newSocket.on('chat history', (chatHistory) => {
      console.log('Chat history:', chatHistory);
      setMessages(chatHistory);

      // Update processedAt if it's the first message from the customer and hasn't been set yet
      if (isFirstMessageFromCustomer.current) {
        const firstCustomerMessage = chatHistory.find(msg => msg.user === 'Customer');
        if (firstCustomerMessage) {
          setProcessedAt(new Date(firstCustomerMessage.timestamp));
          isFirstMessageFromCustomer.current = false;
        }
      }
    });

    newSocket.on('chat message', (message) => {
      console.log('New chat message:', message);
      setMessages((prevMessages) => [...prevMessages, message]);

      // Set processedAt only if it hasn't been set yet and it's the first message from the customer
      if (message.user === 'Customer' && isFirstMessageFromCustomer.current) {
        setProcessedAt(new Date(message.timestamp));
        isFirstMessageFromCustomer.current = false;
      }

      // Update status based on who sent the message
      if (message.user === 'Customer') {
        setStatus('Processing');
        isAdminReplied.current = false; // Reset admin replied status
      } else if (message.user === 'zacks') {
        setStatus('Answered by Merchant');
        isAdminReplied.current = true;
      }
    });

    newSocket.on('status update', (updatedStatus) => {
      console.log('Updated status:', updatedStatus);
      setStatus(updatedStatus.status || 'Available');
    });

    return () => {
      newSocket.disconnect();
      document.body.classList.remove('chat-page');
    };
  }, [slug]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const sendMessage = () => {
    if (inputMessage.trim() && socket) {
      console.log('Sending message:', inputMessage);
      socket.emit('chat message', { slug, message: inputMessage });
      setInputMessage('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <div className="chat-page">
      <div className="chat-container">
        <h2>Order Details</h2>
        <div className="product-container">
          <span>Product: </span>
          <span className="product-box">{product}</span>
        </div>
        <div className="status-container">
          <span>Status: </span>
          <span className={`status-box ${status.toLowerCase().replace(/\s+/g, '-')}`}>
            {status === 'Processing' ? 'Processing' : status}
          </span>
        </div>
        {processedAt && (
          <div className="status-container">
            <span>Processed At: </span>
            <span className="status-box completed">{new Date(processedAt).toLocaleString()}</span>
          </div>
        )}
        <div className="messages-container">
          {messages.map((msg, index) => (
            <div key={index} className="message">
              <span>{msg.user}: </span>
              <span>{msg.message}</span>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className="input-container">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="https://discord.gg/invite"
          />
          <button onClick={sendMessage}>Send</button>
        </div>
		<div className="important-note" title="Important note">
		<span className="note-icon">!</span>
          <div className="note-content">
            <p>If your order is a Discord server boost product, please follow the instructions below:</p>
            <ol>
              <li>Please send a permanent Discord server invite link.</li>
              <li>Delivery can take up to 6 hours (Generally less).</li>
              <li>Don't kick the server boosters, which will be sent to your server (Otherwise you will lose your boosts).</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Admin from './components/Admin';
import Chat from './components/Chat';
import NotFound from './components/NotFound';
import OrdersServerBoost from './components/OrdersServerBoost';
import OrdersAccounts from './components/OrdersAccounts';
import Login from './components/Login';
import RequireAuth from './components/RequireAuth';
import Orders from './components/Orders';
import KinguinResolutionCenter from './components/Kinguin-Resolution-Center';
import ButtonPersonalPage from './components/zacks';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Default false for unauthenticated users

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    setIsAuthenticated(!!token); // Convert token to boolean: true if present, false if not
  }, []);

  return (
    <Router>
      <Routes>
        {/* Login route */}
        <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />

        {/* Protected routes */}
        <Route
          path="/admin/user/0597882781"
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <Admin />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/user/0597882781/server-boost"
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <OrdersServerBoost />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/user/0597882781/accounts"
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <OrdersAccounts />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/user/0597882781/privatee"
          element={
            <RequireAuth isAuthenticated={isAuthenticated}>
              <ButtonPersonalPage />
            </RequireAuth>
          }
        />
        <Route path="/order/:slug" element={<Chat />} />
        <Route path="/Kinguin-Resolution-Center" element={<KinguinResolutionCenter />} />
        <Route path="/orders/:slug" element={<Orders />} />
		
        <Route path="/admin/user/0597882781/private" element={<ButtonPersonalPage />} />
		
        {/* Fallback route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;

import React from 'react';
import './Kinguin-Resolution-Center.css';  // Import the CSS file
import photo1 from './1.png';  // Adjust the path as necessary
import photo2 from './2.png';
import photo3 from './3.png';

const PhotoGallery = () => {
    return (
        <div className="photo-gallery-wrapper">
            <div className="gallery-title-container">
                <h2 className="gallery-title">A Guide on How to Open a Ticket on Kinguin</h2>
            </div>
            <div className="gallery">
                <div className="photo">
                    <div className="photo-label">Step 1</div>
                    <img src={photo1} alt="Photo 1" />
                </div>
                <div className="photo">
                    <div className="photo-label">Step 2</div>
                    <img src={photo2} alt="Photo 2" />
                </div>
                <div className="photo">
                    <div className="photo-label">Step 3</div>
                    <img src={photo3} alt="Photo 3" />
                </div>
            </div>
        </div>
    );
};

export default PhotoGallery;

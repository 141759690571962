// OrdersServerBoost.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import './OrdersServerBoost.css';

const apiUrl = 'https://chat-panel-backend-7a08673c7031.herokuapp.com';
const socket = io(apiUrl);

const OrdersServerBoost = () => {
  const [slug, setSlug] = useState('');
  const [product, setProduct] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [links, setLinks] = useState([]);
  const [sortStatus, setSortStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [linksPerPage] = useState(5);
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [inputPage, setInputPage] = useState('');
  const [fillOptions, setFillOptions] = useState([
    'All boosts have been sent to this discord server >> "  "',
    'Server Boost Count: - ║ Delivered Boost Amount: 14 ║ Total Boosts of The Server: -'
  ]);
  const [showServerNamePopup, setShowServerNamePopup] = useState(false);
  const [serverName, setServerName] = useState('');
  const [boostCount, setBoostCount] = useState('');
  const [totalBoosts, setTotalBoosts] = useState('');
  const [showBoostCountPopup, setShowBoostCountPopup] = useState(false);

  const productOptions = [
    'Discord Server - 14x Boost - 3 Months',
    'Discord Server - 14x Boost - 1 Month',
    'Discord Server - 14x Boost - 1 Week'
  ];

  useEffect(() => {
    fetchLinks();
  }, []);

  useEffect(() => {
    if (selectedSlug) {
      socket.emit('join', selectedSlug);
      socket.on('chat history', (chatHistory) => {
        setMessages(chatHistory);
        scrollToBottom();
      });
      socket.on('chat message', (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
        scrollToBottom();

        const link = links.find((link) => link.slug === selectedSlug);
        if (!link.processedAt && message.user === 'Customer') {
          const updatedLinks = links.map((link) => {
            if (link.slug === selectedSlug) {
              return { ...link, processedAt: message.timestamp };
            }
            return link;
          });
          setLinks(updatedLinks);
        }
      });
      socket.on('status update', (statusUpdate) => {
        const updatedLinks = links.map((link) => {
          if (link.slug === selectedSlug) {
            return { ...link, status: statusUpdate.status };
          }
          return link;
        });
        setLinks(updatedLinks);
        if (statusUpdate.status === 'Completed') {
          alert(
            'This chat has been marked as completed. No further messages can be sent.'
          );
        }
      });
    }

    return () => {
      if (selectedSlug) {
        socket.off('chat history');
        socket.off('chat message');
        socket.off('status update');
      }
    };
  }, [selectedSlug]);

  const fetchLinks = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/links`, {
        withCredentials: true,
      });
      setLinks(response.data);
    } catch (error) {
      console.error('There was an error fetching the links:', error);
    }
  };

  const createLink = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/create-link`,
        { slug, product },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      if (response.data.success) {
        alert('Link created successfully!');
        fetchLinks();
      } else {
        alert('Error creating link: ' + response.data.message);
      }
    } catch (error) {
      console.error('There was an error creating the link:', error);
    }
  };

  const toggleChatStatus = async (slugToToggle, currentStatus) => {
    try {
      const newStatus =
        currentStatus === 'Completed' ? 'Available' : 'Completed';
      const response = await axios.post(
        `${apiUrl}/api/toggle`,
        { slug: slugToToggle, status: newStatus },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      if (response.data.success) {
        alert('Chat status updated successfully!');
        fetchLinks();
      } else {
        alert('Error updating chat status');
      }
    } catch (error) {
      console.error(
        'There was an error updating the chat status:',
        error
      );
    }
  };

  const handleSort = (status) => {
    setSortStatus(status);
  };

  const deleteLink = async (slugToDelete) => {
    try {
      const response = await axios.delete(
        `${apiUrl}/api/links/${slugToDelete}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.success) {
        alert('Link deleted successfully!');
        fetchLinks();
      } else {
        alert('Error deleting link: ' + response.data.message);
      }
    } catch (error) {
      console.error('There was an error deleting the link:', error);
    }
  };

  const handleSelectSlug = (slug) => {
    setSelectedSlug(slug);
    setMessages([]);
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() && selectedSlug) {
      const link = links.find((link) => link.slug === selectedSlug);
      if (link.status !== 'Completed') {
        socket.emit('admin message', {
          slug: selectedSlug,
          message: newMessage,
        });
        setNewMessage('');
      } else {
        alert('Cannot send message. Chat is completed.');
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const generateSlug = () => {
    const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    const newSlug = template.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    setSlug(newSlug);
  };

  const handleProductOptionClick = (option) => {
    setProduct(option);
    setShowOptions(false);
  };
  
  const handleTextBoxClick = (option) => {
    if (option.includes('discord server')) {
      setShowServerNamePopup(true);
    } else {
      setBoostCount('');
      setTotalBoosts('');
      setShowBoostCountPopup(true);
    }
  };
  
  const closeServerNamePopup = () => {
    setShowServerNamePopup(false);
    setServerName('');
  };
  
  const closeBoostCountPopup = () => {
    setShowBoostCountPopup(false);
    setBoostCount('');
    setTotalBoosts('');
  };
  
  const handleServerNameSubmit = () => {
    const updatedOption = `All boosts have been sent to this discord server >> "${serverName}"`;
    setNewMessage(updatedOption);
    closeServerNamePopup();
  };
  
  const handleBoostSubmit = () => {
    const updatedOption = `Server Boost Count: ${boostCount} ║ Delivered Boost Amount: 14 ║ Total Boosts of The Server: ${totalBoosts}`;
    setNewMessage(updatedOption);
    closeBoostCountPopup();
  };

  const filteredLinks = links.filter(
    (link) =>
      link.slug.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (sortStatus === 'Processing&Answered'
        ? link.status === 'Processing' || link.status === 'Answered by Merchant'
        : sortStatus
        ? link.status === sortStatus
        : true)
  );

  const indexOfLastLink = currentPage * linksPerPage;
  const indexOfFirstLink = indexOfLastLink - linksPerPage;
  const currentLinks = filteredLinks.slice(
    indexOfFirstLink,
    indexOfLastLink
  );
  const totalPages = Math.ceil(filteredLinks.length / linksPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePageInputChange = (e) => {
    setInputPage(e.target.value);
  };

  const handlePageInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      const pageNumber = Number(inputPage);
      if (pageNumber > 0 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
      }
      setInputPage('');
    }
  };

  const scrollToBottom = () => {
    const chatContainer = document.querySelector('.chat-messages');
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / 5) * 5;
    return new Array(5)
      .fill()
      .map((_, idx) => start + idx + 1)
      .filter((page) => page <= totalPages);
  };

  const copyUrlToClipboard = (slug) => {
    const url = `https://www.zacks.cc/order/${slug}`;
    navigator.clipboard.writeText(url).then(
      () => {
        alert('URL copied to clipboard');
      },
      () => {
        alert('Failed to copy URL');
      }
    );
  };

  return (
  <div className="admin-page">
    <div className="admin-container">
      <h2>Create a New Order</h2>
      <input
        type="text"
        value={slug}
        onChange={(e) => setSlug(e.target.value)}
        placeholder="Enter a unique slug"
      />
      <button onClick={generateSlug}>Generate a Slug</button>
      <div className="product-input-container">
        <input
          type="text"
          value={product}
          onChange={(e) => setProduct(e.target.value)}
          placeholder="Enter the product"
          onFocus={() => setShowOptions(true)}
          onBlur={() => setTimeout(() => setShowOptions(false), 200)}
        />
        {showOptions && (
          <div className="product-options">
            {productOptions.map((option) => (
              <div
                key={option}
                className="product-option"
                onClick={() => handleProductOptionClick(option)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
      <button onClick={createLink}>Create Link</button>

      <h2>Orders</h2>
      <div className="sort-container">
        <label>Sort by Status:</label>
        <select
          onChange={(e) => handleSort(e.target.value)}
          value={sortStatus}
        >
          <option value="">All</option>
          <option value="Available">Available</option>
          <option value="Processing">Processing</option>
		  <option value="Processing&Answered">Processing & Answered</option>
		  <option value="Answered by Merchant">Answered by Merchant</option>
          <option value="Completed">Completed</option>
        </select>
      </div>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search by slug"
        className="search-box"
      />
      <table className="links-table">
        <thead>
          <tr>
            <th>Slug</th>
            <th>Product</th>
            <th>Status</th>
			<th>Processed At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentLinks.map((link) => (
            <tr key={link.slug}>
              <td>
			  {link.slug}
			  <button
			  onClick={() => deleteLink(link.slug)}
			  style={{ marginLeft: '10px' }} // Add some spacing between the slug and the button
			  >
			  Delete
			  </button>
			  </td>
              <td>{link.product}</td>
              <td>{link.status}</td>
			  <td>{link.processedAt ? new Date(link.processedAt).toLocaleString() : 'Not Processed'}</td>
              <td>
                <button onClick={() => handleSelectSlug(link.slug)}>
                  View Chat
                </button>
                <button
                  onClick={() =>
                    toggleChatStatus(link.slug, link.status)
                  }
                  className="toggle-status-btn"
                >
                  {link.status === 'Completed'
                    ? 'Mark as Available'
                    : 'Mark as Completed'}
                </button>
                <button onClick={() => copyUrlToClipboard(link.slug)}>
                  Copy URL
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        {getPaginationGroup().map((item, index) => (
          <button
            key={index}
            onClick={() => paginate(item)}
            className={`${currentPage === item ? 'active' : ''}`}
          >
            {item}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
        <input
          type="text"
          value={inputPage}
          onChange={handlePageInputChange}
          onKeyPress={handlePageInputKeyPress}
          placeholder={`${currentPage} / ${totalPages}`}
        />
      </div>

          {selectedSlug && (
          <div className="chat-section">
            <h3>Chat with {selectedSlug}</h3>
            <div className="chat-messages">
              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`message ${
                    msg.user === 'zacks'
                      ? 'admin-message'
                      : 'customer-message'
                  }`}
                >
                  <span className="message-user">{msg.user}:</span>
                  <span className="message-text">{msg.message}</span>
                </div>
              ))}
            </div>
            <div className="chat-input">
              <input
                type="text"
                value={newMessage}
                onChange={(e) =>
                  setNewMessage(e.target.value)
                }
                onKeyPress={handleKeyPress}
                placeholder="Type your message..."
              />
              <button onClick={handleSendMessage}>Send</button>
              <div className="dropdown-container">
                <button className="dropdown-button">
                  <img
                    src="options-icon.png"
                    alt="Options"
                    className="options-icon"
                  />
                </button>
                <ul className="dropdown-menu">
                  {fillOptions.map((option) => (
                    <li
                      key={option}
                      onClick={() => handleTextBoxClick(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
        {showServerNamePopup && (
          <div className="popup-box">
            <div className="popup-content">
              <h4>Enter Server Name</h4>
              <input
                type="text"
                value={serverName}
                onChange={(e) =>
                  setServerName(e.target.value)
                }
                placeholder="Server Name"
              />
              <button onClick={handleServerNameSubmit}>
                Submit
              </button>
              <button onClick={closeServerNamePopup}>Close</button>
            </div>
          </div>
        )}
        {showBoostCountPopup && (
          <div className="popup-box">
            <div className="popup-content">
              <h4>Enter Boost Count and Total Boosts</h4>
              <input
                type="text"
                value={boostCount}
                onChange={(e) =>
                  setBoostCount(e.target.value)
                }
                placeholder="Boost Count"
              />
              <input
                type="text"
                value={totalBoosts}
                onChange={(e) =>
                  setTotalBoosts(e.target.value)
                }
                placeholder="Total Boosts"
              />
              <button onClick={handleBoostSubmit}>Submit</button>
              <button onClick={closeBoostCountPopup}>
                Close
              </button>
            </div>
          </div>
        )}
    </div>
  </div>
  );
};

export default OrdersServerBoost;
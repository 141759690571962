import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './OrdersAccounts.css';

const apiUrl = 'https://chat-panel-backend-7a08673c7031.herokuapp.com';

const OrdersAccounts = () => {
  const [slug, setSlug] = useState('');
  const [product, setProduct] = useState('');
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(5);
  const [inputPage, setInputPage] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [deliveryInfo, setDeliveryInfo] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showEstimatedDeliveryTimeModal, setshowEstimatedDeliveryTimeModal] = useState(false);
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  
  const [showTicketsModal, setShowTicketsModal] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [merchantMessage, setMerchantMessage] = useState('');

  useEffect(() => {
    fetchOrders();
    fetchTickets();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/orders`, {
        withCredentials: true,
      });
      setOrders(response.data.orders);
    } catch (error) {
      console.error('There was an error fetching the orders:', error);
    }
  };

  const fetchTickets = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/orders/tickets`);
      const allTickets = response.data;

      // Filter tickets based on the isChatActive status
      const activeTickets = allTickets.filter(ticket => {
        const correspondingOrder = orders.find(order => order.slug === ticket.slug);
        return correspondingOrder && correspondingOrder.isChatActive;
      });

      setTickets(activeTickets);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  };

  const createOrder = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/orders`,
        { slug, product },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      if (response.data.success) {
        alert('Order created successfully!');
        fetchOrders(); // Refresh orders list
      } else {
        alert('Error creating order: ' + response.data.message);
      }
    } catch (error) {
      console.error('There was an error creating the order:', error);
    }
  };
  
  const cancelOrder = async (slug) => {
    try {
      const response = await axios.post(`${apiUrl}/api/orders/cancel`, { slug });
      if (response.data.success) {
        alert('Order canceled successfully');
        // Optionally, refresh the orders list here
      } else {
        alert('Failed to cancel order');
      }
    } catch (error) {
      console.error('Error canceling order:', error);
    }
  };
  
  const handleSetEstimatedDeliveryTimeClick = () => {
    setshowEstimatedDeliveryTimeModal(true);
  };
  
  const handleSpecifyDeliveredTime = async () => {
    try {
      const response = await axios.post(`${apiUrl}/orders/set-estimated-delivery-time`, {
        hours,
        minutes,
      });

      if (response.data.success) {
        alert(`Estimated Delivery Time set to ${response.data.estimatedDeliveryTime}`);
        setshowEstimatedDeliveryTimeModal(false);
      }
    } catch (error) {
      console.error('Error setting estimated delivery time:', error);
    }
  };


  const handleDeliverClick = (slug) => {
    setSelectedSlug(slug);
    setShowModal(true);
  };

  const handleDeliveryInfoChange = (e) => {
    setDeliveryInfo(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${apiUrl}/orders/deliver/${selectedSlug}`, { deliveryInfo });
      if (response.data.success) {
        setShowModal(false);
        fetchOrders(); // Refresh orders list to reflect the updated status
      }
    } catch (error) {
      console.error('Error delivering order:', error);
    }
  };
  
  const copyUrlToClipboard = (slug) => {
    const url = `https://www.zacks.cc/orders/${slug}`;
    navigator.clipboard.writeText(url).then(
      () => {
        alert('URL copied to clipboard');
      },
      () => {
        alert('Failed to copy URL');
      }
    );
  };
  
  const markAvailable = async (slug) => {
    try {
      const response = await axios.post(`${apiUrl}/orders/mark-available/${slug}`);
      if (response.data.success) {
        alert('Order marked as available successfully!');
        fetchOrders(); // Refresh orders list
      } else {
        alert('Error marking order as available: ' + response.data.message);
      }
    } catch (error) {
      console.error('There was an error marking the order as available:', error);
    }
  };

  const deleteOrder = async (orderId) => {
    try {
      const response = await axios.delete(`${apiUrl}/api/orders/${orderId}`, {
        withCredentials: true,
      });
      if (response.data.success) {
        alert('Order deleted successfully!');
        fetchOrders(); // Refresh orders list
      } else {
        alert('Error deleting order: ' + response.data.message);
      }
    } catch (error) {
      console.error('There was an error deleting the order:', error);
    }
  };

  const generateSlug = () => {
    const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    const newSlug = template.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    setSlug(newSlug);
  };
  
  const handleShowTickets = () => {
    setShowTicketsModal(true);
    fetchTickets();
  };
  
  const handleViewTicket = (ticket) => {
    setSelectedTicket(ticket);
    setChatHistory(ticket.reportMessages);
  };

  const handleSendMessage = async () => {
    if (!selectedTicket) return;

    try {
      const response = await axios.post(`${apiUrl}/api/orders/tickets/${selectedTicket.slug}/messages`, {
        user: 'Merchant', // Assuming the sender is the merchant
        message: merchantMessage,
      });

      setChatHistory(response.data.reportMessages);
      setMerchantMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;

  const filteredOrders = statusFilter === 'All' ? orders : orders.filter(order => order.status === statusFilter);

  const searchedOrders = searchTerm === '' ? filteredOrders : filteredOrders.filter(order => order.slug.includes(searchTerm));

  const currentOrders = searchedOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const totalPages = Math.ceil(searchedOrders.length / ordersPerPage);

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / 5) * 5;
    return new Array(5)
      .fill()
      .map((_, idx) => start + idx + 1)
      .filter((page) => page <= totalPages);
  };

  const handlePageInputChange = (e) => {
    setInputPage(e.target.value);
  };

  const handlePageInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      const pageNumber = Number(inputPage);
      if (pageNumber > 0 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
      }
      setInputPage('');
    }
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when search term changes
  };

  return (
    <div className="orders-accounts-container">
      <div className="create-a-new-order-form">
	  <div className="set-delivery-time"> <button onClick={handleSetEstimatedDeliveryTimeClick}>Set Delivery Time</button> </div>
        <h1>Create a New Order</h1>
        <div className="input-group">
          <label htmlFor="slug">Slug:</label>
          <input
            type="text"
            id="slug"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
          />
          <div className="generateSlug">
            <button onClick={generateSlug}>Generate a Slug</button>
          </div>
        </div>
        <div className="input-group">
          <label htmlFor="product">Product:</label>
          <input
            type="text"
            id="product"
            value={product}
            onChange={(e) => setProduct(e.target.value)}
          />
        </div>
        <button onClick={createOrder}>Create Order</button>
		<div className="show-tickets-button"> <button onClick={handleShowTickets}>Show Tickets</button> </div>
      </div>
      
      <h2>Orders</h2>
      <div className="sort-container-">
        <label>Sort by Status:</label>
        <select id="statusFilter" value={statusFilter} onChange={handleStatusFilterChange}>
          <option value="All">All</option>
          <option value="Available">Available</option>
          <option value="Processing">Processing</option>
          <option value="Delivered">Delivered</option>
		  <option value="Canceled">Canceled</option>
        </select>
      </div>
      <div className="search-container-">
        <input
          type="text"
          placeholder="Search by Slug"
          value={searchTerm}
          onChange={handleSearchInputChange}
        />
      </div>
      <div className="orders-table-container">
        <table className="orders-table-">
          <thead>
            <tr>
              <th>Slug</th>
              <th>Product</th>
              <th>Status</th>
			  <th>Requseted at</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentOrders.map((order) => (
              <tr key={order._id}>
			    <td>
				{order.slug}
				<button
				className="delete-button"
			    onClick={() => deleteOrder(order._id)}
				style={{ marginLeft: '10px' }} // Add some spacing between the slug and the button
				>
				Delete
				</button>
				<button onClick={() => cancelOrder(order.slug)}>Cancel</button>
			    </td>
                <td>{order.product}</td>
                <td>
				{order.status}
				<button onClick={() => markAvailable(order.slug)}>Mark Available</button>
			    </td>
				<td>{order.requestedAt}</td>
                <td>
                  <button onClick={() => handleDeliverClick(order.slug)}>
				    Deliver
				  </button>
				  <button onClick={() => copyUrlToClipboard(order.slug)}>
				    Copy URL
				  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showModal && (
          <div className="modal">
            <textarea
              value={deliveryInfo}
              onChange={handleDeliveryInfoChange}
              placeholder="Enter delivery information"
            />
            <button onClick={handleSubmit}>Deliver</button>
            <button onClick={() => setShowModal(false)}>Cancel</button>
          </div>
        )}
		{showEstimatedDeliveryTimeModal && (
		  <div className="modal">
		  <label>
		    Hours:
			<input
			  type="number"
			  name="hours"
			  value={hours}
			  onChange={(e) => setHours(parseInt(e.target.value) || 0)}
			/>
		  </label>
		  <label>
		    Minutes:
		    <input
		      type="number"
			  name="minutes"
			  value={minutes}
			  onChange={(e) => setMinutes(parseInt(e.target.value) || 0)}
		    />
		  </label>
		  <button onClick={handleSpecifyDeliveredTime}>Specify Delivered Time</button>
		  <button onClick={() => setshowEstimatedDeliveryTimeModal(false)}>Cancel</button>
		  </div>
		)}
		
      {showTicketsModal && (
        <div className="modal0">
          <div className="modal-content0">
            <h3>Tickets</h3>
            <table>
              <thead>
                <tr>
                  <th>Slug</th>
                  <th>Order ID</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tickets.map((ticket) => (
                  <tr key={ticket.slug}>
                    <td>{ticket.slug}</td>
                    <td>{ticket.orderId}</td>
                    <td>
                      <button onClick={() => handleViewTicket(ticket)}>View Ticket</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={() => setShowTicketsModal(false)}>Close</button>
          </div>
        </div>
      )}
	  
      {selectedTicket && (
        <div className="modal0">
          <div className="modal-content0">
            <h2>Chat History for Ticket: {selectedTicket.slug}</h2>
            <div className="chat-history0">
              {chatHistory.map((message, index) => (
                <div key={index} className={`message0 ${message.user}`}>
                  <strong>{message.user}:</strong> {message.message}
                </div>
              ))}
            </div>
            <div className="send-message0">
			  <textarea
			    value={merchantMessage}
				onChange={(e) => setMerchantMessage(e.target.value)}
				placeholder="Type your message..."
			  />
			  <button onClick={handleSendMessage}>Send</button>
			</div>
			<button onClick={() => setSelectedTicket(null)}>Close</button>
		  </div>
        </div>
      )}
	  
	</div>
	  

      <div className="pagination-">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        {getPaginationGroup().map((item, index) => (
          <button
            key={index}
            onClick={() => setCurrentPage(item)}
            className={currentPage === item ? 'active' : null}
          >
            {item}
          </button>
        ))}
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
        <input
          type="text"
          value={inputPage}
          onChange={handlePageInputChange}
          onKeyPress={handlePageInputKeyPress}
          placeholder={`${currentPage} / ${totalPages}`}
        />
      </div>
    </div>
  );
};

export default OrdersAccounts;

import React, { useState } from "react";
import "./zacks.css";
import logo from "./zackshoplogo.png"; // Import the image

const ButtonPersonalPage = () => {
  const [isCodePromptOpen, setIsCodePromptOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [privateCode, setPrivateCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const correctCode = "123456789";

  const openCodePrompt = (content) => {
    setModalContent(content);
    setIsCodePromptOpen(true);
  };

  const handleCodeSubmit = () => {
    if (privateCode === correctCode) {
      setIsCodePromptOpen(false);
      setIsModalOpen(true);
      setErrorMessage("");
    } else {
      setErrorMessage("Error: Private Code is incorrect");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeCodePrompt = () => {
    setIsCodePromptOpen(false);
    setErrorMessage("");
    setPrivateCode("");
  };

  return (
    <div className="button-page-">
      <div className="content-wrapper-">
        <img src={logo} alt="Zack Shop Logo" className="logo-" /> {/* Use the imported image */}
        <div className="buttons-container-">
          <button onClick={() => openCodePrompt("e5sl svqf 4sdr wpjf")}>
            Button 1
          </button>
          <button onClick={() => openCodePrompt("nuft 6t42 ein4 21ue")}>
            Button 2
          </button>
          <button onClick={() => openCodePrompt("hhaa 3kl7 ddvx cpa6")}>
            Button 3
          </button>
        </div>
      </div>

      {isCodePromptOpen && (
        <div className="modal-overlay--">
          <div className="modal--">
            <p>Enter The Private Code</p>
            <input
              type="password"
              value={privateCode}
              onChange={(e) => setPrivateCode(e.target.value)}
              placeholder="Private Code"
            />
            <button onClick={handleCodeSubmit} className="submit-button-private">Submit</button>
            <button onClick={closeCodePrompt} className="close-button">
              Close
            </button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="modal-overlay--">
          <div className="modal--">
            <p>{modalContent}</p>
            <button onClick={closeModal} className="close-button">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ButtonPersonalPage;
